<template>
    <!-- Begin Banner Area -->
    <div class="banner-area banner-style-10">
        <div class="container banner-container-2">
            <div class="row">
                <div class="col-lg-6">
                    <div class="banner-img">
                        <img src="images/banner/10-1-677x598.png" alt="Banner">
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="banner-content">
                        <span class="banner-category">Easy Managable</span>
                        <h2 class="banner-title">You can easily manage your banking data.</h2>
                        <p class="banner-desc">That necessitates a robust ecommerce platform that optimizes your store products type specimen book survived not only five centuries but also electronic</p>
                        <p class="banner-desc mb-0">That necessitates a robust ecommerce platform that optimizes your store & products</p>
                        <div class="banner-btn-wrap">
                            <router-link to="/contact" class="btn btn-custom-size btn-lg extbot-primary-5 blackcurrant-hover">Start Free Trial</router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Banner Area End Here -->
</template>
