<template>
    <!-- Begin Banner Area -->
    <div class="banner-area banner-style-11">
        <div class="container banner-container-2">
            <div class="row">
                <div class="col-lg-6">
                    <div class="banner-content">
                        <span class="banner-category">Awesome Infterface</span>
                        <h2 class="banner-title">Unique & clean user interface all screens.</h2>
                        <p class="banner-desc">That necessitates a robust ecommerce platform that
                            optimizes your store & products
                        </p>
                        <div class="banner-btn-wrap">
                            <router-link to="/contact" class="btn btn-custom-size btn-lg extbot-primary-5 blackcurrant-hover">Start Free Trial</router-link>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="banner-img">
                        <div class="banner-sticker-wrap style-1">
                            <div class="scene fill">
                                <div class="expand-width" data-depth="0.2">
                                    <div class="banner-sticker"></div>
                                </div>
                            </div>
                        </div>
                        <div class="single-img">
                            <img src="images/banner/11-1-617x444.png" alt="Banner">
                        </div>
                        <div class="banner-sticker-wrap style-2">
                            <div class="scene fill">
                                <div class="expand-width" data-depth="-0.2">
                                    <div class="banner-sticker"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Banner Area End Here -->
</template>
<script>

</script>
